import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { STATIC_FORM_KEY } from "../consts";
import { Button } from "../components/button";
import * as Alert from "./alert";

export const Contact = () => {
  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "contact.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const [contact, setContact] = useState({
    name: "",
    email: "",
    subject: "Website enquiry",
    honeypot: "", // if any value received in this field, form submission will be ignored.
    message: "",
    replyTo: "@", // this will set replyTo of email to email address entered in the form
    accessKey: STATIC_FORM_KEY, // get your access key from https://www.staticforms.xyz
  });

  const [response, setResponse] = useState({
    type: "",
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => setContact({ ...contact, [e.target.name]: e.target.value });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const res = await fetch("https://api.staticforms.xyz/submit", {
        method: "POST",
        body: JSON.stringify(contact),
        headers: { "Content-Type": "application/json" },
      });

      const json = await res.json();

      if (json.success) {
        setResponse({
          type: "success",
          message: "Thank you for reaching out to me.",
        });
      } else {
        setResponse({
          type: "error",
          message: json.message,
        });
      }
      setIsLoading(false);
    } catch (e) {
      setResponse({
        type: "error",
        message: e.message,
      });
      setIsLoading(false);
    }
  };
  return (
    <div className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <Img
            fluid={image.childImageSharp.fluid}
            className="object-cover w-full h-56 lg:absolute lg:h-full"
            alt="Contact"
          />
        </div>
      </div>
      <div className="relative px-4 pt-12 pb-16 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:pr-8">
          <div className="max-w-md mx-auto sm:max-w-lg lg:mx-0">
            <h2 className="text-3xl font-extrabold tracking-tight leading-9 sm:text-4xl sm:leading-10 text-indigo-600">
              Have a question?
            </h2>
            <p className="mt-4 text-lg text-gray-500 leading-7 sm:mt-3">
              Regarding the products or the training available.
            </p>
            <p className="mt-4 text-lg text-gray-500 leading-7 sm:mt-3">
              I&apos;d love to hear from you.
            </p>
            <form
              method="POST"
              action="https://api.staticforms.xyz/submit"
              onSubmit={handleSubmit}
              className="mt-9 grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8"
            >
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700 leading-5"
                >
                  Name
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    id="name"
                    className="block w-full form-input transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    name="name"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700 leading-5"
                >
                  Email
                </label>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    id="email"
                    className="block w-full form-input transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    type="email"
                    placeholder="Email"
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              {/* Honeypot */}
              <div className="hidden">
                <label className="label">Title</label>
                <div className="control">
                  <input
                    type="text"
                    name="honeypot"
                    className="hidden"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label
                    htmlFor="phone"
                    className="block text-sm font-medium text-gray-700 leading-5"
                  >
                    Phone
                  </label>
                  <span className="text-sm text-gray-500 leading-5">
                    Optional
                  </span>
                </div>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <input
                    id="phone"
                    className="block w-full form-input transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex justify-between">
                  <label
                    htmlFor="message"
                    className="block text-sm font-medium text-gray-700 leading-5"
                  >
                    How can I help you?
                  </label>
                </div>
                <div className="relative mt-1 rounded-md shadow-sm">
                  <textarea
                    id="message"
                    name="message"
                    className="block w-full form-textarea transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                    onChange={handleChange}
                    required
                  ></textarea>
                </div>
              </div>

              <div className="sm:col-span-2">
                <Alert.Success
                  message={response.message}
                  canShow={response.type === "success"}
                />
                <Alert.Error
                  message={response.message}
                  canShow={response.type === "error"}
                />
              </div>
              <div className="text-right sm:col-span-2">
                <Button isLoading={isLoading}>Submit</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
