import React from "react";

export const Stats = () => {
  return (
    <div className="bg-gray-50">
      <div className="px-4 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="text-3xl font-extrabold text-gray-900 leading-9 sm:text-4xl sm:leading-10">
            A proven system for dramatically raising standards
          </h2>
        </div>
      </div>
      <div className="mt-10 bg-indigo-800">
        <div className="relative">
          <div className="absolute inset-0 h-1/2 bg-gray-50"></div>
          <div className="relative px-4 mx-auto max-w-screen-xl sm:px-6 lg:px-8">
            <div className="max-w-4xl mx-auto">
              <dl className="bg-white rounded-lg shadow-lg sm:grid sm:grid-cols-3">
                <div className="flex flex-col p-6 text-center border-b border-gray-100 sm:border-0 sm:border-r">
                  <dt
                    className="order-2 mt-2 text-lg font-medium text-gray-500 leading-6"
                    id="item-1"
                  >
                    schools here and abroad
                  </dt>
                  <dt
                    className="mb-2 text-lg font-medium text-gray-500 leading-6"
                    id="item-1"
                  >
                    Used by over
                  </dt>
                  <dd
                    className="order-1 text-5xl font-extrabold leading-none text-indigo-600"
                    aria-describedby="item-1"
                  >
                    {(3000).toLocaleString()}
                  </dd>
                </div>
                <div className="flex flex-col p-6 text-center border-t border-b border-gray-100 sm:border-0 sm:border-l sm:border-r">
                  <dt className="order-2 mt-2 text-lg font-medium text-gray-500 leading-6">
                    systems sold
                  </dt>
                  <dt
                    className="mb-2 text-lg font-medium text-gray-500 leading-6"
                    id="item-1"
                  >
                    Over
                  </dt>
                  <dd className="order-1 text-5xl font-extrabold leading-none text-indigo-600">
                    {(25000).toLocaleString()}
                  </dd>
                </div>
                <div className="flex flex-col p-6 text-center border-t border-gray-100 sm:border-0 sm:border-l">
                  <dt className="order-2 mt-2 text-lg font-medium text-gray-500 leading-6">
                    children raise standards
                  </dt>
                  <dt
                    className="mb-2 text-lg font-medium text-gray-500 leading-6"
                    id="item-1"
                  >
                    Helping well over
                  </dt>
                  <dd className="order-1 text-5xl leading-none font-extrabold text-indigo-600">
                    {(1000000).toLocaleString()}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
