import React from "react";
import { Container } from "./container";

export const Quote = ({
  children,
  name,
  jobPosition,
}: {
  children: React.ReactNode;
  name: string;
  jobPosition: string;
}) => {
  return (
    <div className={`my-8`}>
      <blockquote className={`md:flex-grow md:flex md:flex-col`}>
        <div className="relative text-lg font-medium leading-7 md:flex-grow">
          <svg
            className="absolute top-0 left-0 w-8 h-8 text-indigo-600 transform -translate-x-3 -translate-y-2"
            fill="currentColor"
            viewBox="0 0 32 32"
          >
            <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
          </svg>
          <p className="relative text-2xl text-white">{children}</p>
        </div>
        <footer>
          <div className="text-base font-medium text-white leading-6">
            {name}
          </div>
          <div className="text-base font-medium text-indigo-200 leading-6">
            {jobPosition}
          </div>
        </footer>
      </blockquote>
    </div>
  );
};

export type Testimonial = {
  name: string;
  jobPosition: string;
  quote: string;
};

export const Testimonials = ({
  first,
  second,
}: {
  first: Testimonial;
  second: Testimonial;
}) => {
  return (
    <section className="bg-indigo-800">
      <Container>
        <div className="md:grid md:grid-cols-2 ">
          <div className="px-4 py-12 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r md:border-indigo-900 lg:pr-16">
            <Quote name={first.name} jobPosition={first.jobPosition}>
              {first.quote}
            </Quote>
          </div>
          <div className="px-4 py-12 border-t-2 border-indigo-900 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
            <Quote name={second.name} jobPosition={second.jobPosition}>
              {second.quote}
            </Quote>
          </div>
        </div>
      </Container>
    </section>
  );
};
