import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import { Container } from "../components/container";
import { Button } from "../components/button";

const Card = ({
  image,
  title,
  linkTo,
  children,
  span = "",
}: {
  span?: string;
  title: string;
  image: FluidObject;
  linkTo?: string;
  children: React.ReactNode;
}) => {
  return (
    <div
      className={`${span} flex flex-col overflow-hidden rounded-lg shadow-lg`}
    >
      <div className="flex-shrink-0">
        <Img className="object-cover w-full h-48" fluid={image} alt="" />
      </div>
      <div className="flex flex-col justify-between flex-1 p-6 bg-white">
        <Link to={linkTo || ""} className="block">
          <h3 className="mt-2 text-xl font-semibold text-gray-900 leading-7">
            {title}
          </h3>
          <p className="mt-3 text-base text-gray-500 leading-6">{children}</p>
        </Link>
        <div className="flex justify-end mt-4">
          {linkTo ? (
            <Button size="small">
              <Link to={linkTo}>More</Link>
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export const Products = () => {
  const {
    maths,
    spelling,
    grammar,
    science,
    behaviour,
    writing,
  } = useStaticQuery(graphql`
    query {
      maths: file(relativePath: { eq: "maths.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      spelling: file(relativePath: { eq: "abc.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      grammar: file(relativePath: { eq: "grammar.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      science: file(relativePath: { eq: "science.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      doughnuts: file(relativePath: { eq: "doughnuts.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      writing: file(relativePath: { eq: "pencils.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      behaviour: file(relativePath: { eq: "Prefect.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const products = [
    {
      linkTo: "/grammar-resources",
      image: grammar.childImageSharp.fluid,
      title: "Grammar",
      quote: "Truly amazing results in grammar: our best ever!",
      cite: "P. Haylock, DHT: Poverest Pri, Kent",
    },
    {
      linkTo: "/spelling-resources",
      image: spelling.childImageSharp.fluid,
      title: "Spelling",
      quote:
        "The staff and children love the systems. The impact is clear and immediate!",
      cite: "C. Ord, DHT: Our Lady of the Rosary, Durham",
    },
    {
      linkTo: "/writing-resources",
      image: writing.childImageSharp.fluid,
      title: "Writing",
      quote:
        "The perfect tool. It really works. Our highest ever writing results. Thank you so much.",
      cite: "C. Greenaway, HT: Wavell Junior, North Yorks",
    },
    {
      linkTo: "/maths-resources",
      image: maths.childImageSharp.fluid,
      title: "Maths",
      quote:
        "Assertive Mentoring is making a real difference to our children.  They absolutely love the Maths!",
      cite: "A. Metcalfe: Spotland Primary, Rochdale",
    },
    {
      linkTo: "/science-resources",
      image: science.childImageSharp.fluid,
      title: "Science",
      quote:
        "Outstanding progress across the school. The staff love it, I love, the kids love it!",
      cite: "E. Pepper, HT: St. Botolph's, Leicester",
    },
    {
      linkTo: "/attitude-behaviour-system",
      image: behaviour.childImageSharp.fluid,
      title: "Attitude, Behaviour System",
      quote:
        "Pupils’ self-confidence and attitudes to learning have changed beyond recognition.",
      cite: "Mount Pleasant Primary, Darlington: Ofsted Report",
    },
  ];

  return (
    <div className="relative pt-16 pb-20 glamour-background bg-gray-50 lg:pb-28 lg:px-8">
      <Container>
        <div className="absolute inset-0">
          <div className="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div className="relative mx-auto max-w-7xl">
          <div className="text-center">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 leading-9 sm:text-4xl sm:leading-10">
              Products
            </h2>
            <p className="max-w-2xl mx-auto mt-3 text-xl text-gray-500 leading-7 sm:mt-4">
              The products ensure consistent practice, accurate assessment,
              focussed teaching and relevant interventions.
              <br /> All resources are fully compatible with the new core
              curriculum.
            </p>
          </div>
          <div className="max-w-lg mx-auto mt-12 grid gap-5 lg:grid-cols-3 lg:max-w-none">
            {products.map((product) => (
              <Card
                key={product.linkTo}
                linkTo={product.linkTo}
                image={product.image}
                title={product.title}
              >
                {product.quote}
                <p>
                  <cite className="text-sm">{product.cite}</cite>
                </p>
              </Card>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};
