import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import SEO from "../components/seo";
import { Products } from "../components/products";
import { Testimonials } from "../components/testimonials";
import { Nav } from "../components/nav";
import { Footer } from "../components/footer";
import { Stats } from "../components/stats";
import { Contact } from "../components/contact";

function IndexPage() {
  const { headerImage } = useStaticQuery(graphql`
    query {
      headerImage: file(relativePath: { eq: "teacher-cropped.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
      <SEO
        keywords={[
          "Teacher",
          "Curriculum",
          "Primary",
          "Assertive",
          "Mentoring",
        ]}
        description="Primary Assertive Mentoring: A Curriculum that works"
        title="Home"
      />
      <div className="relative overflow-hidden bg-white">
        <div className="mx-auto max-w-screen-xl ">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="absolute inset-y-0 right-0 hidden w-48 h-full text-white lg:block transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <Nav />

            <div className="px-4 mx-auto mt-10 max-w-screen-xl sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h2 className="text-4xl font-extrabold tracking-tight text-gray-900 leading-10 sm:text-5xl sm:leading-none md:text-6xl">
                  Assertive
                  <br className="xl:hidden" />
                  <span className="text-indigo-600"> Mentoring</span>
                </h2>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  A fully-resourced and revolutionary, whole-school approach to
                  teaching and learning. A proven system that has been
                  transforming standards for over 10 years.
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="shadow rounded-md">
                    <a
                      href="#products"
                      className="flex items-center justify-center w-full px-8 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent leading-6 rounded-md hover:bg-indigo-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    >
                      Products
                    </a>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link
                      to="/order"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-indigo-700 bg-indigo-100 hover:text-indigo-600 hover:bg-indigo-50 focus:outline-none focus:shadow-outline focus:border-indigo-300 transition duration-150 ease-in-out md:py-4 md:text-lg md:px-10"
                    >
                      Order
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <Img
            fluid={headerImage.childImageSharp.fluid}
            className="object-cover w-full h-56 sm:h-72 md:h-96 lg:w-full lg:h-full"
            alt="Teacher"
          />
        </div>
      </div>
      <div id="products">
        <Products />
      </div>
      <div id="stats">
        <Stats />
      </div>
      <div id="testimonials">
        <Testimonials
          first={{
            quote: "At last something that really works!",
            name: "B. Aylett",
            jobPosition: "HT: Montgomery Junior School, Colchester",
          }}
          second={{
            quote:
              "We would not have believed this amount of progress was even possible.",
            name: "Ofsted",
            jobPosition: "",
          }}
        />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </>
  );
}

export default IndexPage;
